import { SlButton, SlDrawer } from "@shoelace-style/shoelace";
import "./kg-font-size";
import "./kg-high-contrast";

declare var SimpleLightbox;

new SimpleLightbox(".gallery a", {/* options */});

const burgerButton = document.getElementById(
  "burger-menu-trigger",
)! as SlButton;
const burgerMenu = document.getElementById(
  "burger-menu",
)! as SlDrawer;

burgerButton.addEventListener("click", () => {
  burgerMenu.open = !burgerMenu.open;
});

document.querySelectorAll("nav .btn").forEach((btn) => {
  btn.addEventListener("click", () => {
    btn.classList.toggle("opened");
  });
});

document.addEventListener("DOMContentLoaded", () => {
  setTimeout(() => {
    burgerMenu.style.display = "contents";
  }, 1000);
});
