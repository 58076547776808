import { customElement, property, state } from "lit/decorators.js";
import { css, html, LitElement } from "lit";

declare var document: any;
declare var getComputedStyle: any;

@customElement("kg-font-size")
export class KgFontSize extends LitElement {
  @property()
  public label = "";

  @property()
  public mode: "increase" | "decrease" = "increase";

  @state()
  private get fontSizePx(): number {
    const currentSize = getComputedStyle(document.body)
      .getPropertyValue("--base-font-size");

    return parseFloat(currentSize);
  }

  private set fontSizePx(px: number) {
    document.body.style.setProperty("--base-font-size", px + "px");
    localStorage.setItem("fontSizePx", px.toString());
  }

  static styles = css`
span {
  display: block;
  width: 2em;
  height: 2em;
  border-radius: 50%;
  background-color: var(--circle-btn-bg-color, var(--bg-gray));
  color: var(--circle-btn-color, var(--fg-color));
  text-align: center;
  padding: 4px;
  user-select: none;
  cursor: pointer;
}

span:hover,
span:focus-visible,
span:focus {
  outline: solid 2px var(--primary-color);
}
`;

  constructor() {
    super();

    const storedFontSize = localStorage.getItem("fontSizePx");

    if (storedFontSize) {
      this.fontSizePx = parseFloat(storedFontSize);
    }
  }

  protected render(): unknown {
    return html`
<span
  @click=${this.changeFontSize}
  @keypress=${(event: KeyboardEvent) => {
      if (event.key === "Enter") {
        this.changeFontSize();
      }
    }}
  .class=${"font-size-button " + this.mode}
  role="button"
  aria-label=${this.label}
  tabindex="0"
>
 A<sup>${this.mode === "increase" ? "+" : "-"}</sup>
</span>`;
  }

  private changeFontSize() {
    let sign = 1;

    if (this.mode === "decrease") {
      sign = -1;
    }

    this.fontSizePx = this.fontSizePx + sign * 1.5;
  }
}
