import { customElement, property, state } from "lit/decorators.js";
import { css, html, LitElement } from "lit";

declare var document: any;

@customElement("kg-high-contrast")
export class KgHighContrast extends LitElement {
  @property()
  public label = "";

  @state()
  public get isHighContrast(): boolean {
    return document.body.classList.contains("high-contrast");
  }

  public set isHighContrast(v: boolean) {
    localStorage.setItem("high-contrast", v.toString());

    document.body.classList.remove("high-contrast");

    if (v) {
      document.body.classList.add("high-contrast");
    }
  }

  static styles = css`
span {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2em;
  height: 2em;
  border-radius: 50%;
  background-color: var(--circle-btn-bg-color, var(--bg-gray));
  color: var(--circle-btn-color, var(--fg-color));
  text-align: center;
  padding: 4px;
  user-select: none;
  cursor: pointer;
}

span:hover,
span:focus-visible,
span:focus {
  outline: solid 2px var(--primary-color);
}
`;

  constructor() {
    super();

    if (localStorage.getItem("high-contrast") === "true") {
      this.isHighContrast = true;
    } else {
      this.isHighContrast = false;
    }
  }

  protected render(): unknown {
    return html`
<span
  @click=${() => this.toggle()}
  @keypress=${(event: KeyboardEvent) => {
      if (event.key === "Enter") {
        this.toggle();
      }
    }}
  role="button"
  aria-label=${this.label}
  tabindex="0"
>
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Frame">
      <path id="Vector" d="M12.1901 22C17.7041 22 22.1901 17.514 22.1901 12C22.1901 6.486 17.7041 2 12.1901 2C6.67606 2 2.19006 6.486 2.19006 12C2.19006 17.514 6.67606 22 12.1901 22ZM12.1901 4C16.6011 4 20.1901 7.589 20.1901 12C20.1901 16.411 16.6011 20 12.1901 20C7.77906 20 4.19006 16.411 4.19006 12C4.19006 7.589 7.77906 4 12.1901 4Z" fill="#22343D"/>
      <path id="Vector_2" d="M19.1901 12C19.1901 8.134 16.0551 5 12.1901 5V19C16.0551 19 19.1901 15.866 19.1901 12Z" fill="black"/>
    </g>
  </svg>
  
</span>`;
  }

  private toggle() {
    this.isHighContrast = !this.isHighContrast;
  }
}
